import { am, factory } from './utils';

import httpClient from '@/utils/httpClient';

const IFW_LOAD_STREAM = am(`IFW_LOAD_STREAM`);

export default {
  namespaced: true,

  ...factory(
    {
      state: {
        id: null,
        total: 0,
        from: 0,
        data: [],
        queueStatus: {},
        isRequestPending: false,
        isRequestFailed: false
      },
      getters: {
        queueStatus: state => state.queueStatus,
        collection: state => {
          if (!state.data?.length) {
            return [];
          }

          const codes = ['CTFR', 'CTNF', 'NOA', 'CTAV', 'RCEX', 'INTV.SUM.APP'];
          const orderGroup = group => {
            group.items = group.items.sort((a, b) => {
              const orderA = codes.indexOf(a.code) === -1 ? 100 : codes.indexOf(a.code);
              const orderB = codes.indexOf(b.code) === -1 ? 100 : codes.indexOf(b.code);

              return orderA - orderB;
            });
          };

          const sorted = state.data.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });

          return sorted.reduce((groups, item) => {
            const group = groups.find(g => g.key === item.date);

            if (group) {
              group.items.push(item);
              orderGroup(group);
            } else {
              groups.push({
                id: `${item.id}_${state.id}`,
                timestamp: item.createdAt,
                key: item.date,
                items: [item],
                expanded: false
              });
            }
            return groups;
          }, []);
        },
        flatList: state => {
          return state.data.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });
        }
      },
      mutations: {
        [IFW_LOAD_STREAM.STARTED](state, { id, initialize }) {
          state.id = id;
          state.isRequestPending = true;
          state.isRequestFailed = false;
          if (initialize) {
            state.data = [];
          }
        },
        [IFW_LOAD_STREAM.COMPLETED](state, { data, total, queueStatus }) {
          state.isRequestPending = false;

          for (const item of data) {
            if (state.data.find(({ id }) => item.id === id)) {
              continue;
            }
            state.data.unshift(item);
          }

          state.queueStatus = queueStatus;
          state.total = total;
        },
        [IFW_LOAD_STREAM.FAILED](state) {
          state.isRequestPending = false;
          state.isRequestFailed = true;
        },
        ['IFW_LOAD_STREAM.RESET'](state) {
          state.id = null;
          state.total = 0;
          state.from = 0;
          state.data = [];
          state.queueStatus = {};
          state.isRequestPending = false;
          state.isRequestFailed = false;
        }
      },
      actions: {
        async load({ commit }, { id, inventionId, skip }) {
          try {
            commit(IFW_LOAD_STREAM.STARTED, {
              id,
              initialize: typeof skip === 'undefined'
            });

            const responses = await Promise.all([
              httpClient.get(`/api/streams/events/${inventionId}/ifw`),
              httpClient.get(`/api/uspto/applications/${id}`).catch(e => {
                return { documents: [] };
              }),
              httpClient.get(`/api/uspto/downloads/${id}/status`)
            ]);

            const events = responses[0]?.data ?? [];
            const usptoDocuments = responses[1]?.documents ?? [];
            const status = responses[2] ?? {};

            commit(IFW_LOAD_STREAM.COMPLETED, {
              data: usptoDocuments.map(item => ({
                ...item,
                applicationId: id,
                createdAt: events.find(e => e.metadata.id === item.id)
                  ? new Date(events.find(e => e.metadata.id === item.id)?.createdAt)
                  : new Date(item.date)
              })),
              total: usptoDocuments.length,
              queueStatus: status
            });
          } catch (e) {
            commit(IFW_LOAD_STREAM.FAILED);
            throw e;
          }
        },
        async reset({ commit }) {
          commit('IFW_LOAD_STREAM.RESET');
        },
        'stream:added'({ commit, state }, item) {
          commit(IFW_LOAD_STREAM.COMPLETED, {
            data: [{ ...item.metadata, applicationId: state.id, createdAt: new Date(item.createdAt) }].filter(
              item => item.inventionId === state.id && item.type === 'uspto:file.downloaded'
            ),
            total: state.total + 1,
            queueStatus: { ...state.queueStatus, status: 'completed' }
          });
        }
      }
    },
    {
      path: '/api/streams/socket.io',
      actions: {
        subscribe: 'join',
        unsubscribe: 'leave'
      },
      handlers: ['stream:added']
    }
  )('ifw_stream', {
    async getCollection(id) {
      throw new Error('Not implemented.');
    },
    async create({ name, tags, text }) {
      throw new Error('Not implemented.');
    },
    async delete(id) {
      throw new Error('Not implemented.');
    },
    async update(id, args) {
      throw new Error('Not implemented.');
    }
  })
};
