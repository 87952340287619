<template>
  <p-multiselect
    :key="value"
    :value="prepairedValue"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    :search-change-callback="suggestAsync"
    :taggable="true"
    :can-clear="false"
    :object="shorthand"
    :filter-results="true"
    @change="change"
    @update:value="change"
  />
</template>

<script>
import httpClient from '@/utils/httpClient';

import Multiselect from '@/components/common/Multiselect';
export default {
  components: {
    'p-multiselect': Multiselect
  },
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    shorthand: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'update:value'],
  computed: {
    prepairedValue() {
      if (this.shorthand) {
        return this.value.map(value => ({ label: value.split('@')[0], value }));
      }
      return this.value;
    }
  },
  methods: {
    change(value) {
      const prepairedValue = this.shorthand ? value.map(v => v.value) : value;
      this.$emit('change', prepairedValue);
      this.$emit('update:value', prepairedValue);
    },
    async suggestAsync(q) {
      if (q && q.length) {
        const options = await httpClient.get(`/api/suggest/assignees?q=${q}`);
        if (this.shorthand) {
          return options.map(value => ({ label: value.split('@')[0], value }));
        }
        return options;
      }
      return Promise.resolve([]);
    }
  }
};
</script>
