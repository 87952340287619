import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import store from './store/index.js';
import setting from './settings.js';

import Toast from './plugins/ToastService';
import Authorization from './plugins/AuthorizationService';
import Modal from './plugins/ModalService';
import Lock from './plugins/LockService';
import Analytics from './plugins/AnalyticsPlugin';
import Celebration from './plugins/CelebrationService';

const app = createApp(App)
  .use(router)
  .use(
    store({
      previewMode: window.location.search.toLowerCase().includes('previewmode')
    })
  )
  .use(setting);

app.use(Toast);
app.use(Authorization);
app.use(Modal);
app.use(Lock);
app.use(Analytics);
app.use(Celebration);

app.mount('#app');
