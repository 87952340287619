import party from 'party-js';

export default {
  install(app) {
    app.config.globalProperties.$celebrate = function() {
      // const audio = new Audio(`/organ-chord-achievement.mp3`);
      // audio.play();
      // party.sparkles(document.querySelector('body'), {
      //   count: party.variation.range(100, 2000),
      //   size: party.variation.range(0.6, 1.4)
      // });
    };
  }
};
