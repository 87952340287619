import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const LOAD_STREAM = am(`LOAD_STREAM`);

export default {
  namespaced: true,

  ...factory(
    {
      state: {
        id: null,
        total: 0,
        from: 0,
        data: [],
        isRequestPending: false,
        isRequestFailed: true,
        isMoreRequestPending: false
      },
      getters: {
        collection: state => {
          return state.data.sort((a, b) => {
            return a.createdAt - b.createdAt;
          });
        }
      },
      mutations: {
        [LOAD_STREAM.STARTED](state, { id, initialize }) {
          state.id = id;
          state.isRequestFailed = false;
          if (initialize) {
            state.isRequestPending = true;
            state.data = [];
          } else {
            state.isMoreRequestPending = true;
          }
        },
        [LOAD_STREAM.COMPLETED](state, { data, total }) {
          state.isRequestPending = false;

          for (const item of data) {
            if (state.data.find(({ id }) => item.id === id)) {
              continue;
            }
            state.data.unshift(item);
          }
          state.isMoreRequestPending = false;
          state.total = total;
        },
        [LOAD_STREAM.FAILED](state) {
          state.isRequestPending = false;
          state.isRequestFailed = true;
        }
      },
      actions: {
        async load({ commit }, { id, skip, from, to }) {
          try {
            commit(LOAD_STREAM.STARTED, {
              id,
              initialize: typeof skip === 'undefined'
            });

            const params = new URLSearchParams();
            if (skip) {
              params.set('skip', skip);
            }
            if (from) {
              params.set('from', from);
            }
            if (to) {
              params.set('to', to);
            }

            const response = await httpClient.get(`/api/streams/${id}?` + params.toString());

            commit(LOAD_STREAM.COMPLETED, {
              data: response.data.map(item => ({ ...item, createdAt: new Date(item.createdAt) })),
              total: response.total
            });
          } catch (e) {
            commit(LOAD_STREAM.FAILED);
            throw e;
          }
        },

        'stream:added'({ commit, state }, item) {
          commit(LOAD_STREAM.COMPLETED, {
            data: [{ ...item, createdAt: new Date(item.createdAt) }].filter(item => item.inventionId === state.id),
            total: state.total + 1
          });
        }
      }
    },
    {
      path: '/api/streams/socket.io',
      actions: {
        subscribe: 'join',
        unsubscribe: 'leave'
      },
      handlers: ['stream:added']
    }
  )('stream', {
    async getCollection(id) {
      throw new Error('Not implemented.');
    },
    async create({ name, tags, text }) {
      throw new Error('Not implemented.');
    },
    async delete(id) {
      throw new Error('Not implemented.');
    },
    async update(id, args) {
      throw new Error('Not implemented.');
    }
  })
};
