import { am } from './utils';

import httpClient from '@/utils/httpClient';
import logger from '@/utils/logger';

export const GET_NOTES_FOR_MILESTONE = am('GET_NOTES_FOR_MILESTONE');
export const CREATE_NOTES_FOR_MILESTONE = am('CREATE_NOTES_FOR_MILESTONE');
export const UPDATE_NOTES_FOR_MILESTONE = am('UPDATE_NOTES_FOR_MILESTONE');
export const DELETE_NOTES_FOR_MILESTONE = am('DELETE_NOTES_FOR_MILESTONE');

export const state = {
  notes: {}
};

export const actions = {
  async getNotesForMilestones({ commit }, milestoneIds) {
    const promises = milestoneIds.map(async milestoneId => {
      commit(GET_NOTES_FOR_MILESTONE.STARTED, milestoneId);
      const collection = await httpClient.get(`/api/workflow/milestone-notes/${milestoneId}`);
      commit(GET_NOTES_FOR_MILESTONE.COMPLETED, { id: milestoneId, collection });
    });
    await Promise.all(promises);
  },
  async createNote({ commit }, payload) {
    const note = await httpClient.post(`/api/workflow/milestone-notes`, payload);
    commit(CREATE_NOTES_FOR_MILESTONE.COMPLETED, note);
  },
  async updateNote({ commit }, { id, payload }) {
    const note = await httpClient.patch(`/api/workflow/milestone-notes/${id}`, payload);
    commit(UPDATE_NOTES_FOR_MILESTONE.COMPLETED, note);
  },
  async deleteNote({ commit }, { id, milestoneId }) {
    const note = await httpClient.delete(`/api/workflow/milestone-notes/${id}`);
    commit(DELETE_NOTES_FOR_MILESTONE.COMPLETED, { id, milestoneId });
  }
};

export const mutations = {
  [GET_NOTES_FOR_MILESTONE.STARTED](state, id) {
    state.notes[id] = {
      isRequestPending: true,
      collection: []
    };
  },
  [GET_NOTES_FOR_MILESTONE.COMPLETED](state, { id, collection }) {
    state.notes[id] = {
      isRequestPending: false,
      collection: collection
    };
  },
  [CREATE_NOTES_FOR_MILESTONE.COMPLETED](state, note) {
    state.notes[note.milestoneId].collection.push(note);
  },
  [UPDATE_NOTES_FOR_MILESTONE.COMPLETED](state, note) {
    const index = state.notes[note.milestoneId].collection.findIndex(n => n.id === note.id);
    state.notes[note.milestoneId].collection[index] = note;
  },
  [DELETE_NOTES_FOR_MILESTONE.COMPLETED](state, { id, milestoneId }) {
    const index = state.notes[milestoneId].collection.findIndex(n => n.id === id);
    state.notes[milestoneId].collection.splice(index, 1);
  }
};

export default {
  namespaced: true,
  state,

  mutations,
  actions
};
