<template>
  <div class="modal-overlay">
    <section class="confirmation-modal">
      <header>
        <h2 v-if="!!title">
          {{ title }}
        </h2>
      </header>
      <div>
        <div>{{ message }}</div>
      </div>
      <div>
        <hub-checkbox v-if="doNotShowAgainVisible" v-model:value="doNotShowAgain" label="Do not show me this again" />
      </div>
      <footer>
        <p-button :test-id="'cancel-button'" @click="$emit('cancel')">{{ cancel }}</p-button>
        <p-button :test-id="'confirm-button'" color="secondary" @click="$emit('confirm', { doNotShowAgain })">{{ confirm }}</p-button>
      </footer>
    </section>
  </div>
</template>

<script>
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    'p-button': Button,
    'hub-checkbox': Checkbox
  },
  props: {
    title: {
      type: String,
      default: null,
      required: false
    },
    message: {
      type: String,
      default: null,
      required: true
    },
    confirm: {
      type: String,
      default: 'Confirm'
    },
    cancel: {
      type: String,
      default: 'Cancel'
    },
    doNotShowAgainVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['confirm', 'cancel'],
  data() {
    return {
      doNotShowAgain: false
    };
  }
};
</script>

<style lang="scss">
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
  transition: 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: opacity;
  will-change: opacity;
}
.confirmation-modal {
  header {
    h2 {
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      padding: 1rem 2rem 0.75rem 2rem;
    }
  }
  > div {
    overflow-y: auto;
    overflow-x: auto;
    padding: 1.75rem 2rem 0.75rem 2rem;
    white-space: pre-line;
  }

  footer {
    padding: 0.75rem 2rem 0.75rem 2rem;
    display: flex;
    justify-content: flex-end;
  }
  display: grid;
  grid-template-rows: max-content minmax(0, 100px) max-content max-content;
  max-width: 40%;
  min-width: 30%;
  background: var(--theme-background);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  transition: opacity 0.15s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 7;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}
</style>
