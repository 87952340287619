<template>
  <div class="search-input-wrapper">
    <hub-text-field
      v-model="searchText"
      :test-id="'search-invention'"
      placeholder="Search for invention"
      @enter="onEnter"
      @blur="onBlur"
      @focus="onFocus"
      @up="increment"
      @down="decrement"
    />
    <div v-if="isDropdownVisible" class="search-result">
      <ul v-if="isRequestFailed" class="search-list">
        <li class="search-list-item-meta error">
          <label>An error occured while an attempt to get invention collection.</label>
          <div></div>
        </li>
      </ul>
      <div v-else-if="isRequestPending || queue.length" style="justify-self: center; align-self: center">
        <p-icon name="loading" spin size="xxlg"></p-icon>
      </div>
      <ul v-else class="search-list">
        <li v-if="collection.length === 0 && searchText && searchText.length" class="search-list-item-meta">
          <label>Nothing found for '{{ searchText }}'.</label>
          <div></div>
        </li>
        <li v-if="collection.length !== 0" class="search-list-item-meta">
          <label>
            <span v-if="total > size">{{ size }} results of {{ total > 9999 ? 'more than 10000' : total.toString() }}</span>
            <span v-else>{{ collection.length }} results</span>
            <span v-if="searchText && searchText.length"> for '{{ searchText }}'</span>
          </label>
          <div></div>
        </li>
        <li v-for="item of collection" :key="item.id" class="search-list-item" :class="{ selected: selected === item.id }" @click="navigate(item.id)">
          <label>{{ item.title }}</label>
          <div>{{ item.references }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

import Icon from './common/Icon';

import TextField from '@/components/common/TextField.vue';

export default {
  components: {
    'p-icon': Icon,
    'hub-text-field': TextField
  },
  data() {
    return {
      searchText: '',
      debounce: null,
      queue: [],
      selected: null,
      isDropdownVisible: false
    };
  },
  computed: {
    ...mapState({
      collection: s => s.inventions.collection,
      total: s => s.inventions.total,
      size: s => s.inventions.size,
      isRequestPending: s => s.inventions.isGetCollectionRequestPending,
      isRequestFailed: s => s.inventions.isGetCollectionRequestFailed
    })
  },
  watch: {
    searchText: {
      handler() {
        this.queue.push(this.searchText);
      },
      immediate: true
    },
    'collection.length'() {
      this.selected = this.collection.length ? this.collection[0].id : null;
    },
    selected() {
      const element = document.querySelector('.selected');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  },

  created() {
    this.debounce = true;
    this.performSearch();
  },
  methods: {
    async performSearch() {
      const frame = this.queue.splice(0, this.queue.length);
      if (frame.length) {
        const text = encodeURIComponent(frame.pop());
        try {
          await this.$store.dispatch('inventions/getCollection', { text });
        } catch (e) {}
      }
      if (this.debounce) {
        setTimeout(this.performSearch, 250);
      }
    },
    onEnter(event) {
      this.isDropdownVisible = false;
      if (this.collection.length && this.selected) {
        this.navigate(this.selected);
      }
      event.target.blur();
    },
    onBlur() {
      const that = this;
      setTimeout(() => {
        that.isDropdownVisible = false;
        that.selected = null;
        this.searchText = '';
        this.performSearch();
        this.debounce = false;
      }, 500);
    },
    onFocus() {
      this.debounce = true;
      this.isDropdownVisible = true;
      if (this.collection.length) {
        this.selected = this.collection[0].id;
      }
      this.performSearch();
    },
    navigate(id) {
      this.$router.push({ path: `/inventions/${id}` });
      this.isDropdownVisible = false;
      this.selected = null;
      this.searchText = '';
    },
    increment() {
      if (this.collection.length === 0) {
        return;
      }
      const index = this.collection.findIndex(i => i.id === this.selected);
      if (index === -1) {
        this.selected = this.collection[0].id;
        return;
      }
      if (index === 0) {
        this.selected = this.collection[this.collection.length - 1].id;
        return;
      }
      this.selected = this.collection[index - 1].id;
    },
    decrement() {
      if (this.collection.length === 0) {
        return;
      }
      const index = this.collection.findIndex(i => i.id === this.selected);
      if (index === -1 || index === this.collection.length - 1) {
        this.selected = this.collection[0].id;
        return;
      }
      this.selected = this.collection[index + 1].id;
    }
  }
};
</script>
<style lang="scss" scoped>
.search-input-wrapper {
  position: relative;
}
.search-result {
  position: absolute;
  z-index: 100;
  height: 400px;
  overflow: hidden;
  top: 100%;
  margin-top: 10px;
  border-radius: 5px;
  width: 100%;
  background: var(--theme-header-surface);
  .search-list {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
  }
  .search-list-item-meta {
    padding: 1rem 0.75rem;

    label {
      font-weight: 600;
      font-size: 0.65rem;
    }

    &.error {
      label {
        color: var(--theme-error);
      }
    }
  }
  .search-list-item {
    padding: 0.5rem 1rem;
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
    grid-template-rows: max-content max-content;
    background: var(--theme-header-surface);
    cursor: pointer;
    label {
      font-weight: 600;

      grid-column: 1/2;
      grid-row: 1/2;
      cursor: pointer;
    }

    div {
      font-size: 0.75rem;
      grid-column: 1/2;
      grid-row: 2/3;
    }

    button {
      grid-column: 2/3;
      grid-row: 1/3;
      align-self: center;
    }

    &:hover,
    &.selected {
      background-color: var(--theme-primary);
    }
  }
}
</style>
