import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const GET_TASK_TEMPLATE_COLLECTION = am(`GET_TASK_TEMPLATE_COLLECTION`);
const GET_STATUS_COLLECTION = am(`GET_STATUS_COLLECTION`);
const GET_INVENTION_BY_ID = am(`GET_INVENTION_BY_ID`);

const GET_TASK_HISTORY = am(`GET_TASK_HISTORY`);
const UPDATE_TASK_HISTORY = am(`UPDATE_TASK_HISTORY`);

const populateTaskOrder = (task, getters) => {
  if (!task) {
    return;
  }

  const taskWorkflow = task.workflow;
  if (!taskWorkflow) {
    return;
  }

  const stepTemplate = getters['milestones/getMilestoneTemplates'](taskWorkflow?.id)
    ?.find(template => template.id === taskWorkflow.templateId)
    ?.steps.find(step => step.id === taskWorkflow.stepId);

  task.order = stepTemplate?.display?.position[0] || 0;
};

export default {
  namespaced: true,
  ...factory(
    {
      state: {
        templateCollection: [],
        isGetTemplateCollectionPending: false,
        isGetTemplateCollectionFailed: false,

        statusCollection: [],
        isGetStatusCollectionPending: false,
        isGetStatusCollectionFailed: false,

        invention: null,
        isGetInventionPending: false,
        isGetInventionFailed: false,

        taskHistory: [],
        isGetTaskHistoryPending: false,
        isGetTaskHistoryFailed: false,

        isUpdateTaskHistoryPending: false
      },
      mutations: {
        [GET_TASK_TEMPLATE_COLLECTION.STARTED](state) {
          state.templateCollection = [];
          state.isGetTemplateCollectionPending = true;
          state.isGetTemplateCollectionFailed = false;
        },

        [GET_TASK_TEMPLATE_COLLECTION.COMPLETED](state, { collection }) {
          state.templateCollection = collection;
          state.isGetTemplateCollectionPending = false;
        },
        [GET_TASK_TEMPLATE_COLLECTION.FAILED](state) {
          state.isGetTemplateCollectionFailed = true;
          state.isGetTemplateCollectionPending = false;
        },

        [GET_STATUS_COLLECTION.STARTED](state) {
          state.statusCollection = [];
          state.isGetStatusCollectionPending = true;
          state.isGetStatusCollectionFailed = false;
        },
        [GET_STATUS_COLLECTION.COMPLETED](state, { collection }) {
          state.statusCollection = collection;
          state.isGetStatusCollectionPending = false;
        },
        [GET_STATUS_COLLECTION.FAILED](state) {
          state.isGetStatusCollectionFailed = true;
          state.isGetStatusCollectionPending = false;
        },

        [GET_INVENTION_BY_ID.STARTED](state) {
          state.invention = [];
          state.isGetInventionPending = true;
          state.iisGetInventionFailed = false;
        },
        [GET_INVENTION_BY_ID.COMPLETED](state, { invention }) {
          state.invention = invention;
          state.isGetInventionPending = false;
        },
        [GET_INVENTION_BY_ID.FAILED](state) {
          state.isGetInventionFailed = true;
          state.isGetInventionPending = false;
        },
        [GET_TASK_HISTORY.STARTED](state) {
          state.taskHistory = [];
          state.isGetTaskHistoryPending = true;
          state.isGetTaskHistoryFailed = false;
        },
        [GET_TASK_HISTORY.COMPLETED](state, collection) {
          state.isGetTaskHistoryPending = false;
          state.isGetTaskHistoryFailed = false;
          state.taskHistory = collection;
        },
        [UPDATE_TASK_HISTORY.STARTED](state) {
          state.isUpdateTaskHistoryPending = true;
        },
        [UPDATE_TASK_HISTORY.COMPLETED](state, collection) {
          collection.reverse().forEach(item => {
            if (!state.taskHistory.find(i => i.eventLogId === item.eventLogId)) {
              state.taskHistory.unshift(item);
            }
          });
          state.isUpdateTaskHistoryPending = false;
        }
      },
      actions: {
        async getInventionById({ commit }, { inventionId }) {
          try {
            commit(GET_INVENTION_BY_ID.STARTED);

            const invention = await httpClient.get(`/api/inventions/${inventionId}`);

            commit(GET_INVENTION_BY_ID.COMPLETED, { invention });
          } catch (e) {
            commit(GET_INVENTION_BY_ID.FAILED);
            throw e;
          }
        },
        async getTemplateCollection({ state, commit }) {
          try {
            if (state.templateCollection.length) {
              return;
            }
            commit(GET_TASK_TEMPLATE_COLLECTION.STARTED);

            const workflow = await httpClient.get(`/api/workflow/v2/workflows/settings/templates`);
            commit(GET_TASK_TEMPLATE_COLLECTION.COMPLETED, { collection: workflow.steps });
          } catch (e) {
            commit(GET_TASK_TEMPLATE_COLLECTION.FAILED);
            throw e;
          }
        },
        async getStatusCollection({ commit }, data) {
          try {
            commit(GET_STATUS_COLLECTION.STARTED);

            const params = new URLSearchParams();

            if (data && Object.keys(data).length) {
              for (const key of Object.keys(data)) {
                if (Array.isArray(data[key])) {
                  if (data[key].length) {
                    for (const entry of data[key]) {
                      params.append(key, entry);
                    }
                  } else {
                    params.append(key, []);
                  }
                } else if (data[key] !== null && typeof data[key] !== 'undefined') {
                  params.append(key, data[key]);
                }
              }
            }

            const response = await httpClient.get('/api/workflow/search/tasks?' + params.toString());

            commit(GET_STATUS_COLLECTION.COMPLETED, { collection: Object.keys(response?.aggs?.status || {}) });
          } catch (e) {
            commit(GET_STATUS_COLLECTION.FAILED);
            throw e;
          }
        },
        async getTaskHistory({ commit }, { taskId }) {
          commit(GET_TASK_HISTORY.STARTED);
          try {
            const response = await httpClient.get(`/api/streams/events/tasks/${taskId}?size=50`);

            commit(GET_TASK_HISTORY.COMPLETED, response.data);
          } catch (e) {
            commit(GET_TASK_HISTORY.FAILED);
            throw e;
          }
        },
        async updateTaskHistory({ commit, state }, taskId) {
          if (state.isUpdateTaskHistoryPending || state.isGetTaskHistoryPending) {
            return;
          }
          commit(UPDATE_TASK_HISTORY.STARTED);
          const resp = await httpClient.get(`/api/streams/events/tasks/${taskId}?size=5`);
          commit(UPDATE_TASK_HISTORY.COMPLETED, resp.data);
        },
        async assignTasks({}, payload) {
          await httpClient.post('/api/workflow/tasks/assign', payload);
        },
        reset({ state }) {
          state.isGetRequestPending = false;
          state.isGetRequestFailed = false;
          state.isUpdateRequestPending = false;
          state.isUpdateRequestFailed = false;
        },
        async 'stream:added'({ commit, rootState, rootGetters, dispatch }, item) {
          switch (item?.type) {
            case 'task:created':
              {
                commit('reports/myInventions/taskCreated', item, { root: true });
                populateTaskOrder(updatedTask, rootGetters);
                const tasks = await httpClient.get(`/api/workflow/search/tasks?qs=${item.metadata.task.id}&size=1`);
                if (!tasks.data && !tasks.data.length) {
                  return;
                }
                const updatedTask = tasks.data[0];
                if (!updatedTask) {
                  return;
                }

                commit('reports/myInventions/taskFetched', { inventionId: item.inventionId, task: updatedTask }, { root: true });
              }
              break;
            case 'task:updated':
              {
                const tasks = await httpClient.get(`/api/workflow/search/tasks?qs=${item.metadata.task.id}&size=1`);
                if (!tasks.data && !tasks.data.length) {
                  return;
                }
                const updatedTask = tasks.data[0];
                if (!updatedTask) {
                  return;
                }
                populateTaskOrder(updatedTask, rootGetters);
                if (updatedTask.id === item.id) {
                  commit('UPDATE_TASKS_COMPLETED', updatedTask);
                }
                commit('reports/myInventions/taskFetched', { inventionId: item.inventionId, task: updatedTask }, { root: true });
              }
              break;
            case 'milestone:created':
              {
                const exists = rootState.reports.myInventions?.details[item.inventionId]?.milestones.find(
                  milestone => milestone.workflow?.milestoneId === item.metadata.id
                );
                if (exists) {
                  return;
                }
                dispatch('milestones/bulkGetTemplates', [item.metadata.id], { root: true });
                commit('reports/myInventions/milestoneCreated', item, { root: true });
                const resp = await httpClient.get(`/api/workflow/milestones/inventions/${item.inventionId}`);

                const milestone = resp.find(m => m.workflow.milestoneId === item.metadata.id);

                if (milestone) {
                  commit('reports/myInventions/milestoneFetched', milestone, { root: true });
                }
              }
              break;
            case 'milestone:updated':
              {
                const m = rootState.reports.myInventions.details[item.inventionId].milestones.find(m => m.workflow?.milestoneId === item.metadata.id);
                if (!m || m.isUpdating) {
                  return;
                }

                commit('reports/myInventions/milestoneUpdated', item, { root: true });
              }
              break;
            case 'milestone:finished':
              {
                commit('reports/myInventions/milestoneFinished', { inventionId: item.inventionId, milestoneId: item.metadata.id }, { root: true });
              }
              break;
            default:
              break;
          }
        }
      }
    },
    {
      path: '/api/streams/socket.io',
      actions: {
        subscribe: 'join',
        unsubscribe: 'leave'
      },
      handlers: ['stream:added']
    }
  )(
    'tasks',
    {
      async getById(id) {
        return httpClient.get(`/api/workflow/tasks/${id}`);
      },
      async getCollection(obj) {
        const params = new URLSearchParams();

        if (obj && Object.keys(obj).length) {
          for (const key of Object.keys(obj)) {
            if (Array.isArray(obj[key])) {
              if (obj[key].length) {
                for (const entry of obj[key]) {
                  params.append(key, entry);
                }
              } else {
                params.append(key, []);
              }
            } else if (obj[key] !== null && typeof obj[key] !== 'undefined') {
              params.append(key, obj[key]);
            }
          }
        }

        const response = await httpClient.get('/api/workflow/search/tasks?' + params.toString());
        return {
          ...response,
          skip: obj.skip,
          size: obj.size,
          sort: obj.sort
        };
      },
      async create(data) {
        const task = await httpClient.post(`/api/workflow/tasks/`, data);
        const params = new URLSearchParams();
        params.append('qs', task.id);
        params.append('size', 1);
        const response = await httpClient.get('/api/workflow/search/tasks?' + params.toString());

        return response && response.data && response.data[0];
      },
      async update(id, { updatedAt, ...data }) {
        if (updatedAt) {
          await httpClient.put(`/api/workflow/tasks/${id}?updatedAt=${updatedAt}`, data);
        } else {
          await httpClient.put(`/api/workflow/tasks/${id}`, data);
        }
        const params = new URLSearchParams();
        params.append('qs', id);
        params.append('size', 1);
        const response = await httpClient.get('/api/workflow/search/tasks?' + params.toString());

        return response && response.data && response.data[0];
      },
      async delete(id) {
        return httpClient.delete(`/api/workflow/tasks/${id}`);
      }
    },
    {
      filters: {
        namespaced: true,
        ...factory({
          state: {},
          mutations: {},
          actions: {},
          getters: {
            system(state, getters, rootState) {
              return [
                {
                  id: '9201729614272025',
                  title: 'My Tasks',
                  type: 'system',
                  data: {
                    qs: '',
                    dueAt: '',
                    status: [],
                    assignees: [rootState.identity.email],
                    projectAssignees: []
                  },
                  metadata: []
                },
                {
                  id: '9443030870984088',
                  title: 'My Projects',
                  data: {
                    status: [],
                    assignees: [],
                    projectAssignees: [rootState.identity.email]
                  },
                  metadata: []
                }
              ];
            },
            team(state, getters, rootState) {
              return Array.isArray(rootState?.identity?.teams)
                ? rootState.identity.teams.map(team => ({
                    id: team.id,
                    type: 'team',
                    title: team.name,
                    data: {
                      qs: '',
                      dueAt: '',
                      status: [],
                      assignees: [team.name],
                      projectAssignees: []
                    },
                    metadata: []
                  }))
                : [];
            },
            my(state) {
              return state.collection.map(item => ({ ...item, type: 'personal' }));
            },
            all(state, getters) {
              return [...getters.system, ...getters.team, ...getters.my];
            }
          }
        })('filters', {
          async getById(id) {
            const collection = await httpClient.get(`/api/workflow/filters`);
            return collection.data.find(item => item.id === id);
          },
          async getCollection() {
            return httpClient.get(`/api/workflow/filters`);
          },
          async create({ title, data }) {
            return httpClient.post(`/api/workflow/filters`, { title, data });
          },
          async delete(id) {
            return httpClient.delete(`/api/workflow/filters/${id}`);
          },
          async update(id, { title, data }) {
            return httpClient.put(`/api/workflow/filters/${id}`, { title, data });
          }
        })
      }
    }
  )
};
