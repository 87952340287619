import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const TAXONOMY = am(`TAXONOMY`);

export default {
  namespaced: true,
  ...factory({
    state: {
      taxonomy: {
        loading: false,
        failed: false,
        collection: []
      }
    },
    mutations: {
      [TAXONOMY.STARTED](state) {
        state.taxonomy.loading = true;
        state.taxonomy.failed = false;
      },
      [TAXONOMY.COMPLETED](state, collection) {
        state.taxonomy.loading = false;
        state.taxonomy.failed = false;
        state.taxonomy.collection = collection;
      },
      [TAXONOMY.FAILED](state) {
        state.taxonomy.loading = false;
        state.taxonomy.failed = true;
      },
      ['TAXONOMY.RESET'](state) {
        state.taxonomy.loading = false;
        state.taxonomy.failed = false;
        state.taxonomy.collection = [];
      }
    },
    getters: {
      taxonomy: state => query => {
        if (!query?.length) {
          return state.taxonomy.collection;
        }

        const qs = query.toLowerCase();

        const words = qs
          ? qs
              .split(' ')
              .map(e => e.trim().toLowerCase())
              .filter(e => e)
          : [];

        const result = words.length
          ? state.taxonomy.collection.filter(element => words.every(word => element.keywords.find(key => key.startsWith(word))))
          : state.taxonomy.collection;

        return result;
      }
    },
    actions: {
      async getByReferences(store, references) {
        let workflow = null;
        try {
          const params = references.map(r => 'references=' + r).join('&');
          workflow = await httpClient.get(`/api/workflow/v2/workflows/by/references?${params}`);
        } catch (e) {}
        try {
          if (!workflow) {
            workflow = await httpClient.get(`/api/workflow/v2/workflows/by/default`);
          }
        } catch (e) {}

        return workflow;
      },
      async taxonomy({ commit }) {
        try {
          commit(TAXONOMY.STARTED);

          const collection = await httpClient.get('/api/workflow/v2/workflows/taxonomy');
          for (const element of collection) {
            element.keywords = element.title
              .replace('/', '')
              .split(' ')
              .map(w => w.trim().toLowerCase());
          }

          commit(TAXONOMY.COMPLETED, collection);
        } catch (e) {
          commit(TAXONOMY.FAILED);
        }
      },
      resetTaxonomy({ commit }) {
        commit('TAXONOMY.RESET');
      }
    }
  })('workflows', {})
};
