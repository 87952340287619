<template>
  <div>
    <hub-modal :visible="true" closable="true" class="profile-modal" @close="close">
      <template #title>Profile</template>
      <section v-if="loading" class="loading">
        <hub-icon name="loading" spin size="lg" style></hub-icon>
      </section>
      <form v-else class="form" @submit.stop.prevent="submit">
        <div class="form-row"><hub-text v-model="firstName" label="First name" :warning="!firstName" /></div>
        <div class="form-row">
          <hub-text v-model="lastName" label="Last name" :warning="!lastName" />
        </div>
        <div class="form-row">
          <p-assignees v-model:value="assistants" label="My assistants" placeholder="" />
        </div>
        <div v-if="sponsors?.length" class="form-row">
          <p-assignees v-model:value="sponsors" label="I assist" placeholder="" :disabled="true" />
        </div>
      </form>
      <template #footer>
        <div v-if="!loading" class="footer-buttons">
          <p-button v-if="hasChanges" variant="text" @click="onCancel">Cancel</p-button>
          <p-button type="button" color="primary" :test-id="'ok-save-button'" :disabled="!canSave" @click.prevent.stop="submit()">{{
            hasChanges ? 'Save' : 'Ok'
          }}</p-button>
        </div>
      </template>
    </hub-modal>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import Text from '@/components/common/TextField';
import Assignees from '@/components/Assignees';

import httpClient from '@/utils/httpClient';

export default {
  components: {
    'hub-icon': Icon,
    'hub-modal': Modal,
    'hub-text': Text,
    'p-button': Button,
    'p-assignees': Assignees
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  data() {
    return {
      loading: false,
      firstName: '',
      lastName: '',
      assistants: [],
      sponsors: [],
      user: null,
      assistantsTeam: null
    };
  },
  computed: {
    assistantsChanged() {
      if (this.assistantsTeam) {
        return (
          this.assistantsTeam.members.find(a => !this.assistants.includes(a)) || this.assistants.find(a => !this.assistantsTeam.members.includes(a))
        );
      } else {
        return this.assistants.length > 0;
      }
    },
    hasUserChanges() {
      return this.user.firstName !== this.firstName || this.user.lastName !== this.lastName;
    },
    hasChanges() {
      try {
        return this.hasUserChanges || this.assistantsChanged;
      } catch {
        return true;
      }
    },
    canSave() {
      return this.firstName && this.lastName;
    }
  },
  async created() {
    try {
      this.loading = true;
      const [user, assistantsTeam, sponsors] = await Promise.all([
        httpClient.get('/api/auth/users/me'),
        httpClient.get('/api/auth/teams/assistants'),
        httpClient.get('/api/auth/teams/sponsors/my')
      ]);
      this.user = user;
      this.assistantsTeam = assistantsTeam;

      this.firstName = this.user?.firstName || '';
      this.lastName = this.user?.lastName || '';
      this.assistants = this.assistantsTeam ? [...this.assistantsTeam.members] : [];
      this.sponsors = sponsors;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    onCancel() {
      this.close();
    },
    async submit() {
      if (!this.hasChanges) {
        this.close();
        return;
      }
      try {
        this.loading = true;
        if (this.hasUserChanges) {
          const obj = {
            firstName: this.firstName,
            lastName: this.lastName
          };

          await httpClient.patch('/api/auth/users/me', obj);
        }
        if (this.assistantsChanged) {
          const adddedAssistants = this.assistantsTeam ? this.assistants.filter(a => !this.assistantsTeam.members.includes(a)) : this.assistants;
          const removedAssistants = this.assistantsTeam ? this.assistantsTeam.members.filter(a => !this.assistants.includes(a)) : [];
          const addPromises = adddedAssistants.map(email =>
            httpClient.post('/api/auth/teams/assistants/add', {
              email
            })
          );
          const removePromises = removedAssistants.map(email => httpClient.delete(`/api/auth/teams/${this.assistantsTeam.id}/members/${email}`));
          await Promise.all([...addPromises, ...removePromises]);
        }
        this.$store.dispatch('identity/initialize');
        this.close();
      } catch (e) {
        this.$toast.error({
          title: 'Failed to save changes',
          message: `Please, try again later or contact our development team.`
        });
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
.profile-modal {
  display: flex;
  padding: 10px;
  color: var(--theme-on-surface);

  .modal {
    min-height: 60%;
    height: 60%;
    width: 30%;
    grid-template-rows: max-content minmax(0, 1fr) max-content;
  }

  .modal > div {
    padding-left: 2rem;
    padding-right: 2rem;

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    form {
      background: transparent !important;
      display: grid;
      grid-gap: 0.5rem;
      padding-top: 1rem;
      .form-row {
        margin-bottom: 0.6rem;
      }
    }

    .footer-buttons {
      display: flex;
      justify-content: right;
      width: 100%;
    }
  }

  .modal > footer {
    padding: 20px;
  }
}
</style>
