<template>
  <section class="shell" :class="{ preview: previewMode }">
    <aside v-if="!previewMode">
      <nav>
        <ul class="nav-list">
          <!-- <li class="nav-list-item">
            <router-link to="/" exact><p-icon name="home" size="lg"></p-icon></router-link>
          </li> -->
          <li class="nav-list-item">
            <router-link to="/reports/my-inventions" :class="{ 'router-link-active': $route.path === '/' }">
              <p-icon name="chart-timeline" size="lg"></p-icon>
            </router-link>
          </li>
          <li class="nav-list-item">
            <router-link to="/inventions" :class="{ 'router-link-active': $route.path.startsWith('/inventions') }">
              <p-icon name="view-split-vertical" size="lg"></p-icon>
            </router-link>
          </li>
          <li class="nav-list-item">
            <router-link to="/tasks">
              <p-icon name="feature-search" size="lg"></p-icon>
            </router-link>
          </li>
          <li class="nav-list-item">
            <router-link to="/boards" :class="{ 'router-link-active': $route.path.startsWith('/boards') }">
              <p-icon name="view-week" size="lg"></p-icon>
            </router-link>
          </li>
          <li class="nav-list-item" style="position: absolute; bottom: 5px; left: 50%; transform: translateX(-50%)">
            <router-link to="/applications">
              <p-icon name="download" size="lg"></p-icon>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- <footer>
        <p-button variant="text" @click="toggleExpanded"
          ><p-icon v-if="expanded" name="left-left"></p-icon><p-icon v-else name="right-right"></p-icon
        ></p-button>
      </footer> -->
    </aside>

    <router-view />
  </section>
</template>

<script>
import { lw } from '@/utils/lw';
import { mapState } from 'vuex';

import Icon from './common/Icon';

const LOCAL_STORAGE_ASIDE_EXPANDED = 'LOCAL_STORAGE_ASIDE_EXPANDED';

export default {
  components: {
    'p-icon': Icon
  },
  computed: {
    ...mapState({
      previewMode: s => s.previewMode
    })
  }
};
</script>

<style lang="scss" scoped>
.shell {
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);

  &.preview {
    grid-template-columns: 1fr;
  }

  width: 100%;
  height: 100%;

  aside {
    color: var(--theme-on-surface);
    background-color: var(--theme-surface);
    width: 50px;
    display: grid;
    overflow: visible;
    grid-template-rows: minmax(0, 1fr);
    grid-template-columns: minmax(0, 1fr);
    margin-right: 0.5rem;
    nav {
      overflow: visible;
      position: relative;
    }
    .nav-list {
      display: grid;
      font-size: 1rem;
      padding: 0;
      align-items: center;
      justify-content: center;
      grid-gap: 0.5rem;
      grid-template-rows: repeat(4, 50px) minmax(0, 1fr);
      grid-template-columns: minmax(0, 1fr);

      .nav-list-item {
        // text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        a {
          i {
            color: var(--theme-on-surface);
          }

          &.router-link-active {
            cursor: default;
            i {
              color: var(--theme-primary);
            }
          }

          &.router-link-active ~ .nav-list-item-anc {
            cursor: default;
            i {
              color: var(--theme-primary);
            }
          }
        }

        > span {
          font-size: 1.5rem;
          display: inline-flex;
          margin-right: 5px;
          width: 30px;
          height: 100%;
          align-items: center;
        }

        .nav-list-item-anc {
          cursor: pointer;
          &.active {
            i {
              color: var(--theme-primary-accent);
            }
          }
        }
        .nav-list-item-anc ~ .nav-list-item-sub {
          position: absolute;
          left: 3.35rem;
          z-index: 1;
          top: -2rem;
          transform: translateX(-5px);
          padding-left: 5px;
          max-width: 250px;
          min-width: 150px;
          > section {
            background: var(--theme-surface);
            border: 1px solid var(--theme-highlight);
            border-radius: 2px;
            padding: 0;
          }
          &:after {
            position: absolute;
            content: '';
            border-width: 5px;
            border-color: transparent var(--theme-highlight) transparent transparent;
            border-style: solid;
            top: 2.8rem;
            left: 0;
            transform: translate(-5px, -50%);
            z-index: 1;
          }
        }

        &:hover {
          .nav-list-item-anc ~ .nav-list-item-sub {
            display: block;
          }
        }
      }
    }

    footer {
      display: flex;
      width: 100%;

      justify-content: center;
      align-items: center;
    }

    &.expanded {
      width: 250px;

      footer {
        justify-content: flex-end;
      }
    }
  }

  // aside ~ section {
  //   width: 100%;
  //   height: 100%;
  //   display: flex;
  //   flex: 1;
  //   overflow-y: auto;
  // }
}
</style>
