import { startOfTomorrow, startOfToday, addDays, startOfWeek, endOfWeek } from 'date-fns';

export default () => [
  { name: 'overdue', from: new Date('2020-01-01'), to: startOfToday() },
  { name: 'today', from: startOfToday(), to: startOfTomorrow() },
  { name: 'tomorrow', from: startOfTomorrow(), to: addDays(startOfTomorrow(), 1) },
  { name: 'next 3 days', from: startOfToday(), to: addDays(startOfToday(), 4) },
  { name: 'this week', from: startOfWeek(new Date()), to: addDays(endOfWeek(new Date()), 1) },
  { name: 'no due date', from: null, to: null }
];
