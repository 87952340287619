import { createStore } from 'vuex';

import identity from './identity';
import authentication from './authentication';
import inventions from './inventions';
import stream from './stream';
import ifw_stream from './ifw-stream';
import messages from './messages';
import tasks from './tasks';
import applications from './applications';
import boards from './boards';
import filters from './filters';
import reports from './reports/';
import steps from './steps';
import milestones from './milestones';
import workflows from './workflows';
import emails from './emails';
import docketing from './docketing';
import milestoneNotes from './milestoneNotes';
import directoryIndex from './directoryIndex';

export default (initialState = {}) =>
  createStore({
    modules: {
      identity,
      authentication,
      inventions,
      stream,
      ifw_stream,
      messages,
      tasks,
      applications,
      boards,
      filters,
      reports,
      steps,
      milestones,
      workflows,
      emails,
      docketing,
      milestoneNotes,
      directoryIndex
    },
    state: { ...initialState },
    mutations: {},
    actions: {}
  });
