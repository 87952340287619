import { factory, am } from './utils';

const INITIALIZE_LIST = am(`GET_FILTER_BY_ID`);
const GET_TASK_COLLECTION_BY_LIST_ID = am(`GET_COLLECTION`);

import httpClient from '@/utils/httpClient';
import { jsonObjectToQueryString } from '@/utils/httpClient';
import { getContext } from '@/components/search/context';
import dueAt from '@/components/search/dueAt';

export default {
  namespaced: true,
  ...factory({
    state: {},
    mutations: {},
    actions: {}
  })(
    'boards',
    {
      async getById(id) {
        const item = await httpClient.get(`/api/workflow/boards/${id}`);
        return {
          ...item,
          filters: item.filters.filter(s => !!s)
        };
      },
      async getCollection(obj) {
        return httpClient.get(`/api/workflow/boards/`);
      },
      async create(data) {
        const board = await httpClient.post(`/api/workflow/boards/`, data);
        return board;
      },
      async update(id, data) {
        return await httpClient.patch(`/api/workflow/boards/${id}`, data);
      },
      async delete(id) {
        return httpClient.delete(`/api/workflow/boards/${id}`);
      }
    },
    {
      lists: {
        namespaced: true,

        state: {
          collection: [],
          tasks: {
            isGetCollectionRequestPending: true,
            collection: [],
            total: 0,
            skip: 0,
            size: 0
          }
        },
        mutations: {
          [INITIALIZE_LIST.STARTED]() {},
          [INITIALIZE_LIST.COMPLETED](state, filter) {
            const item = state.collection.find(item => item.id === filter.id);
            if (item) {
              item.title = filter.title;
              item.layout = filter.layout;
              item.data = [];
              item.total = 0;
            } else {
              state.collection.push({
                id: filter.id,
                layout: filter.layout,
                type: filter.type,
                title: filter.title,
                isPending: true,
                total: 0,
                data: []
              });
            }
          },
          [INITIALIZE_LIST.FAILED](state, filter) {
            const item = state.collection.find(item => item.id === filter.id);
            if (item) {
            } else {
              state.collection.push({
                id: filter.id,
                isFailed: true,
                total: 0,
                data: []
              });
            }
          },
          [GET_TASK_COLLECTION_BY_LIST_ID.STARTED](state, { id }) {
            for (const item of state.collection) {
              if (item.id === id) {
                item.isPending = true;
              }
            }
          },
          [GET_TASK_COLLECTION_BY_LIST_ID.COMPLETED](state, { id, data, total, refresh }) {
            for (const board of state.collection) {
              if (board.id !== id) {
                continue;
              }

              board.isPending = false;
              board.total = total;

              if (refresh) {
                board.data = [];
              }

              for (const item of data) {
                if (board.data.find(({ id }) => item.id === id)) {
                  continue;
                }
                board.data.push(item);
              }
            }
          },
          [GET_TASK_COLLECTION_BY_LIST_ID.FAILED](state, { id }) {
            for (const board of state.collection) {
              if (board.id !== id) {
                continue;
              }
              board.isPending = false;
              board.isFailed = true;
            }
          }
        },
        actions: {
          async getById({ commit, rootGetters }, { id }) {
            try {
              commit(INITIALIZE_LIST.STARTED);
              const filter = rootGetters['filters/byId'](id);

              if (!filter) {
                commit(INITIALIZE_LIST.FAILED, { id });
              } else {
                commit(INITIALIZE_LIST.COMPLETED, filter);
              }
            } catch (e) {
              commit(INITIALIZE_LIST.FAILED, { id });
            }
          },
          async exec({ commit, rootGetters }, { id, skip = 0, size, refresh }) {
            try {
              commit(GET_TASK_COLLECTION_BY_LIST_ID.STARTED, { id });

              const context = (await httpClient.get(`/api/workflow/filters/${id}`))?.data;
              const { from, to } = dueAt().find(item => item.name === context.dueAt) || {};
              const response = await httpClient.post('/api/workflow/search/tasks?', {
                qs: context.qs,
                status: context.status,
                assignees: context.assignees,
                projectAssignees: context.projectAssignees,
                assigneesConjugation: context.assigneesConjugation || 'or',
                dueAt: from && to ? ['from:' + from.toISOString(), 'to:' + to.toISOString()] : from === null && to === null ? ['null'] : undefined,
                workflows: context.workflows,
                skip,
                size: size || 25,
                sort: null
              });

              commit(GET_TASK_COLLECTION_BY_LIST_ID.COMPLETED, {
                id,
                data: response.data.map(item => ({ ...item, createdAt: new Date(item.createdAt) })),
                total: response.total,
                refresh
              });
            } catch (e) {
              commit(GET_TASK_COLLECTION_BY_LIST_ID.FAILED, { id });
              throw e;
            }
          }
        }
      }
    }
  )
};
